import { formatDate } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbDateParserFormatter, NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AppSettings } from 'src/app/app-settings';
import { ConfigTicketsComponent } from 'src/app/components/new-lok/config-tickets/config-tickets.component';
import { NewTicketComponent } from 'src/app/components/new-lok/new-ticket/new-ticket.component';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EditEventService } from 'src/app/services/edit-event.service';
import { EventosService } from 'src/app/services/eventos.service';
import { FechasService } from 'src/app/services/fechas.service';
import { TicketsService } from 'src/app/services/tickets.service';
import { DialogTicketComponent } from '../../dialogs-event/dialog-ticket/dialog-ticket.component';

@Component({
  selector: 'app-tickets-type',
  templateUrl: './tickets-type.component.html',
  styleUrls: ['./tickets-type.component.scss']
})
export class TicketsTypeComponent implements OnInit {

  @Input() idEvent!: any;

  tickets!: any[];
  ticketsBase!: any[];

  displayedColumns: string[] = ['actions', 'section', 'price', 'description', 'limits', 'sold'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  loaded: boolean = false;
  loadingPage: boolean = true;

  page = 1;
  event!: any;

  finishScroll: boolean = false;
  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;
  configTickets!: any;
  allowCreate: any = false;

  // Config
  formConfig!: FormGroup;
  typeDatepicker!: string;
  // Start and End Date
  startStringDate!: any;
  endStringDate!: any;
  startDate!: NgbDate;
  endDate!: NgbDate;

  publishDate!: any;
  publishStringDate!: any;

  hourInit!: any;
  minuteInit!: any;
  hourEnd!: any;
  minuteEnd!: any;
  editingConfig: boolean = false;
  loadingEdit: boolean = false;

  constructor(private ticketService: TicketsService, private dialog: MatDialog, private router: Router, public formatter: NgbDateParserFormatter,
    private activatedRoute: ActivatedRoute, private eventService: EventosService, private auth: AuthenticationService,
    private fechasService: FechasService, private editEventService: EditEventService, private formBuilder: FormBuilder) { }


  ngOnInit(): void {
    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS') {
        this.allowCreate = true;
      }
    });
    this.formConfig = this.formBuilder.group({
      startSales: [],
      endSales: [],
      publishDate: [],
      assumeFees: [true],
      needValidation: [false],
      progPublish: [false],
      maxTickets: [],
    });
  }

  ngAfterViewInit() {
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.idEvent = +params.idEvent;
        this.eventService.getEventByIdAdmin(this.idEvent).subscribe({
          next: (data) => {
            this.event = data;
            this.configTickets = {
              startSales: this.event.startSales,
              endSales: this.event.endSales,
              assumeFees: this.event.assumeFees,
              needValidation: this.event.needValidation,
              maxTickets: this.event.maxTikets,
              publishDate: this.event.publishDate || null,
              progPublish: this.event.publishDate ? true : false,
              // soldOut: this.event.publishDate ? true : false,
            };

            if (this.configTickets?.startSales) {
              if (typeof (this.configTickets.startSales) == 'string') {
                let start = new Date(this.configTickets.startSales);
                this.startDate = new NgbDate(start.getFullYear(), start.getMonth() + 1, start.getDate());
                this.configTickets.startSales = new Date(this.configTickets.startSales);
              }
              if (typeof (this.configTickets.endSales) == 'string') {
                let end = new Date(this.configTickets.endSales);
                this.endDate = new NgbDate(end.getFullYear(), end.getMonth() + 1, end.getDate());
                this.configTickets.endSales = new Date(this.configTickets.endSales);
              }
              this.startStringDate = formatDate(this.configTickets.startSales, 'dd/MM/yyyy', 'es');
              this.hourInit = this.configTickets.startSales.getHours();
              this.minuteInit = this.configTickets.startSales.getMinutes();
            }
            if (this.configTickets?.endSales) {
              this.endStringDate = formatDate(this.configTickets.endSales, 'dd/MM/yyyy', 'es');
              this.hourEnd = this.configTickets.endSales.getHours();
              this.minuteEnd = this.configTickets.endSales.getMinutes();
            }
            if (this.configTickets.publishDate) {
              this.publishStringDate = formatDate(this.configTickets.publishDate, 'dd/MM/yyyy', 'es');
            }
            this.formConfig.setValue(this.configTickets);
            this.formConfig.valueChanges.subscribe((value) => {
              this.editingConfig = true;
            });
          }
        });
        this.getData();
      }
    });
  }

  getData() {
    this.tickets = [];
    this.ticketService.getTikcetsByEvent(this.idEvent).subscribe({
      next: (res) => {
        let result = res.map((ticket: any) => {
          let add = ticket[0];
          add['purchases'] = ticket.purchases;
          let arr: any[] = add.priceTickets;
          add.priceTickets = arr.sort((a: any, b: any) => {
            return b.price - a.price;
          });
          add.price = add.priceTickets[arr.length - 1].price;
          add.openMore = false;
          return add;
        });
        this.tickets = this.tickets.concat(result);
        if (this.tickets.length <= 0 || this.tickets.length < 20) {
          this.finishScroll = true;
        }
        this.dataSource = new MatTableDataSource(this.tickets);

        this.loaded = true;
        this.loadingPage = false;
      }
    })
  }

  getMinPrice() {
    this.tickets = this.tickets.map((ticket: any) => {
      let add = ticket[0];
      add['purchases'] = ticket.purchases;
      let arr: any[] = add.priceTickets;
      add.priceTickets = arr.sort((a: any, b: any) => {
        return b.price - a.price;
      });
      add.price = add.priceTickets[arr.length - 1].price;
      add.openMore = false;
      return add;
    });
  }

  editTicket(tickets: any, index: number) {
    if (this.allowCreate) {
      const dialogRef = this.dialog.open(DialogTicketComponent, {
        panelClass: ['info-dialog', 'dialog-edit-lok', 'not-padding'],
        data: {
          tickets: tickets, edit: true, idMap: this.event.mapEvent?.id
        }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          tickets = data.result;
          let arr: any[] = tickets.priceTickets;
          tickets.priceTickets = arr.sort((a: any, b: any) => {
            return b.price - a.price;
          });
          tickets.price = tickets.priceTickets[arr.length - 1].price;
          this.tickets[index] = tickets;
          let payload = { tickets: this.tickets, map: this.event.mapEvent, preciodesde: this.event.preciodesde };
          this.editTickets(payload);
          this.tickets[index] = tickets?.filter((value) => value.price != null);
        }
      });
    }
  }

  editTickets(payload: any) {
    this.fechasService.editLevelPrice(this.event.id, payload).subscribe({
      next: (data) => {
        if (AppSettings.DEBUG) { console.log(data) }
      }
    })
  }

  deleteTicket(ticket: any, i: any) {
    this.tickets.splice(i, 1);
    let payload = { tickets: this.tickets, map: this.event.mapEvent, preciodesde: this.event.preciodesde };
    this.editTickets(payload);
  }

  openCreateTicket(ticket: any = true, i?: number) {
    let newTicket = null;
    if (ticket != true) {
      newTicket = ticket
    }
    let levels = this.event.mapEvent?.id ? this.tickets : null;
    const dialogRef = this.dialog.open(NewTicketComponent, {
      panelClass: ['info-dialog', 'not-padding'],
      data: { ticket: newTicket, venue: this.event.place, levels: levels, map: this.event.mapEvent?.id, dates: this.event.fechas, hours: this.event.horarios }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let payload = { tickets: result, map: this.event.mapEvent };
        this.addTicket(payload);
      }
    });
  }

  addTicket(payload: any) {
    this.fechasService.addTicketLevels(this.event.id, payload).subscribe({
      next: (data) => {
        this.getData();
        if (AppSettings.DEBUG) { console.log(data) }
      }
    })
  }

  openConfig() {
    // const dialogRef = this.dialog.open(ConfigTicketsComponent, {
    //   panelClass: ['info-dialog', 'not-padding'],
    //   data: this.configTickets,
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.configTickets = result;
    //     this.editConfig();
    //   }
    // });
  }

  saveDate(event: any) {
    if (event) {
      const date = new Date(this.formatter.format(event));
      date.setHours(0);
      date.setMinutes(0);
      if (this.typeDatepicker == 'start') {
        this.startDate = event;
        this.formConfig.controls['startSales'].setValue(date);
        this.startStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
      if (this.typeDatepicker == 'end') {
        this.endDate = event;
        this.formConfig.controls['endSales'].setValue(date);
        this.endStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
      if (this.typeDatepicker == 'auto') {
        this.publishDate = event;
        this.formConfig.controls['publishDate'].setValue(date);
        this.publishStringDate = formatDate(date, 'dd/MM/yyyy', 'es');
      }
    }
    this.saveConfig();
    this.typeDatepicker = null;
  }

  saveConfig() {
    if (this.hourInit && this.minuteInit != null) {
      const dateInit = this.formConfig.value.startSales;
      if (dateInit) {
        dateInit.setHours(this.hourInit);
        dateInit.setMinutes(this.minuteInit);
        this.configTickets.startSales = dateInit;
        this.formConfig.setValue(this.configTickets);
      }
    }

    if (this.hourEnd && this.minuteEnd != null) {
      const dateEnd = this.formConfig.value.endSales;
      if (dateEnd) {
        dateEnd.setHours(this.hourEnd);
        dateEnd.setMinutes(this.minuteEnd);
        this.configTickets.endSales = dateEnd;
        this.formConfig.setValue(this.configTickets);
      }
    }
  }

  editConfig() {
    this.loadingEdit = true;
    this.editEventService.editConfig(this.event.id, this.formConfig.value).subscribe({
      next: (data) => {
        this.editingConfig = false;
        this.loadingEdit = false;
        this.infoDialog('La configuración se ha guardado correctamente.', false);
        if (AppSettings.DEBUG) { console.log(data) }
      }, 
      error: (err) => {
        this.loadingEdit = false;
        this.infoDialog('¡Vaya! Algo ha fallado, vuelve a intentarlo más tarde.', true);
        if (AppSettings.DEBUG) { console.log(err) }
      }
    })
  }

  openDatePicker(type: string) {
    if (this.typeDatepicker) {
      this.typeDatepicker = null;
      return;
    }
    this.typeDatepicker = type;
  }

  changeHourInit(event: any) {
    this.hourInit = event;
    this.saveConfig();
  }

  changeMinuteInit(event: any) {
    this.minuteInit = event;
    this.saveConfig();
  }

  changeHourEnd(event: any) {
    this.hourEnd = event;
    this.saveConfig();
  }

  changeMinuteEnd(event: any) {
    this.minuteEnd = event;
    this.saveConfig();
  }

  infoDialog(msg: string, icon: boolean) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

  soldOutEvent() {
    // this.eventosService.putEventos(this.event, { soldOut: !this.isSoldOut }).subscribe({
    //   next: (res) => {
    //     this.isSoldOut = !this.isSoldOut;
    //     const dialogRef = this.dialog.open(InfoCardComponent, {
    //       panelClass: 'info-dialog',
    //       data: {
    //         icon: false,
    //         text: 'Evento actualizado correctamente.',
    //         btnCancel: false
    //       }
    //     });
    //     dialogRef.afterClosed().subscribe({
    //       next: () => {
    //         this.router.navigate(['/panel-control/events/detail/info-event', this.event]);
    //       }
    //     });
    //   }
    // });
  }

}

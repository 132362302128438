<div class="reports-event">
    <!-- Informes avanzados -->
    <div class="d-flex justify-content-between align-items-center">
        <p class="title-tab">Informe avanzado del evento</p>
    </div>
    <div>
        <div class="container-filters m-0">
            <div class="filters-row d-flex justify-content-between">
                <!-- Fecha -->
                <div class="w-100">
                    <div class="">
                        <p class="title-filter-report">Rango de fechas <i class="fa-solid fa-info-circle"
                                matTooltip="Selecciona la fecha inicio y fin de compra para generar el informe."
                                matTooltipHideDelay="200" matTooltipClass="tooltip-reports"></i></p>
                    </div>
                    <mat-form-field appearance="fill" class="select-filter-panel select-icon-report"
                        [class.filter-report-active]="stringDate" (click)="openDatePicker=!openDatePicker">
                        <mat-select-trigger matPrefix style="vertical-align: middle;">
                            <i class="fa-light fa-calendar icon-search"></i>
                        </mat-select-trigger>
                        <input matInput placeholder="Seleccionar" class="pointer-in" readonly [(ngModel)]="stringDate">
                        <mat-select-trigger matSuffix style="vertical-align: middle;">
                            <i class="fal fa-times pointer-in icon-delete-range" *ngIf="stringDate"
                                (click)="clearRangeDates($event)"></i>
                            <i class="fal fa-angle-down pointer-in"></i>
                        </mat-select-trigger>
                    </mat-form-field>
                    <div class="padding-option">
                        <app-custom-calendar-panel [datesRange]="datesRange" [openDatePicker]="openDatePicker"
                            (closeEvent)="openDatePicker = false"
                            (datesEvent)="selectDates($event)"></app-custom-calendar-panel>
                    </div>
                </div>

                <!-- Metodo de pago -->
                <div class="w-100">
                    <div class="">
                        <p class="title-filter-report">Método de pago</p>
                    </div>
                    <mat-form-field appearance="fill" class="select-filter-panel"
                        [class.filter-report-active]="methodSelected && methodSelected?.length > 0">
                        <mat-select #listPlace class="text-black" panelClass="selectPanelControl"
                            placeholder="Método de pago" multiple [(ngModel)]="methodSelected"
                            disableOptionCentering="false">
                            <!-- Opción "Todos" -->
                            <!-- <mat-option [value]="'all'">
                                <span>Todos</span>
                            </mat-option> -->
                            <mat-option *ngFor="let method of methodsOptions" [value]="method.id"
                                [disabled]="allSelected">
                                <span>{{method.name}}</span></mat-option>
                            <mat-select-trigger *ngIf="methodSelected?.length == methodsOptions?.length">
                                <!-- Mostrar "Todos" si está seleccionado, de lo contrario mostrar las opciones seleccionadas -->
                                <span>Todos</span>
                            </mat-select-trigger>
                        </mat-select>

                        <mat-select-trigger matSuffix style="vertical-align: middle;">
                            <i class="fal fa-angle-down pointer-in"></i>
                        </mat-select-trigger>
                    </mat-form-field>
                </div>
            </div>
            <div class="filters-row">
                <!-- Info filters -->
                <div class="container-info-reports">
                    <p class="m-0 title-filter-report">Selecciona el tipo de informe</p>
                </div>
                <section class="container-options">
                    <mat-radio-group [(ngModel)]="typeReport" (change)="changeTypeReport()">
                        <mat-radio-button value="global" class="pointer-in"><span>Informe global</span> <i
                                matTooltip="Visión general con un desglose de eventos, recaudación total, y el canal de venta utilizado."
                                matTooltipHideDelay="200" matTooltipClass="tooltip-reports"
                                class="fa-solid fa-info-circle"></i></mat-radio-button>
                        <mat-radio-button value="detail" class="pointer-in"><span>Informe detalle</span> <i
                                matTooltip="Incluye toda la información específica de un evento. Ideal para un análisis completo."
                                matTooltipHideDelay="200" matTooltipClass="tooltip-reports"
                                class="fa-solid fa-info-circle"></i></mat-radio-button>
                    </mat-radio-group>
                </section>
            </div>
            <div class="filters-row">
                <!-- Format file -->
                <div class="container-info-reports">
                    <p class="m-0 title-filter-report">Selecciona el formato del informe</p>
                </div>
                <section class="container-options">
                    <mat-radio-group [(ngModel)]="formatReport">
                        <mat-radio-button *ngIf="typeReport=='global'" value="pdf" class="pointer-in"><span>Formato
                                PDF</span>
                            <i class="fa-regular fa-file-pdf option-format"></i></mat-radio-button>
                        <mat-radio-button value="csv" class="pointer-in"><span>Formato EXCEL</span> <i
                                class="fa-regular fa-file-spreadsheet option-format"></i></mat-radio-button>
                    </mat-radio-group>
                </section>
                <div class="container-report-group" *ngIf="formatReport=='csv' && typeReport=='detail'">
                    <p class="m-0 title-column-report">Detalles del informe</p>
                    <mat-radio-group [(ngModel)]="allColumns" (change)="selectAllColumns()">
                        <mat-radio-button class="m-0 option-column-report" [value]="true">Todas</mat-radio-button>
                    </mat-radio-group>
                    <mat-divider></mat-divider>
                    <div class="d-grid">
                        <mat-checkbox (click)="selectColumn(column, i)" class="option-column-report"
                            [(ngModel)]="column.selected"
                            *ngFor="let column of reportsGroupEnabled; let i = index">{{column.name}}</mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="reports-download">
                <div class="btns-create">
                    <div class="btn-create btn-bono m-0" (click)="downloadPdf()">
                        <i class="fal fa-download"></i>
                        <p class="m-0">Descargar informe</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Asistencia -->
    <div>
        <div class="d-flex justify-content-between align-items-center">
            <p class="title-tab">Asistencia</p>
            <p style="margin-right: 16px;"><strong>Total: </strong>{{totalReaded}}</p>
        </div>
        <div>
            <div class="margin-table" *ngIf="ticketsSold; else divNotTickets">
                <!-- Tabla entradas por nivel (mapa) -->
                <div class="table-events table-panel table-grey" *ngIf="loaded">
                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z28" matSort
                        (matSortChange)="sortData($event)">
                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let ticket; let i = index">
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef><b>Fecha</b></th>
                            <td mat-cell *matCellDef="let ticket">
                                <span>{{ticket.date | date: 'EEE, d \'de\' MMM \'de\' y'}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="time">
                            <th mat-header-cell *matHeaderCellDef><b>Hora</b></th>
                            <td mat-cell *matCellDef="let ticket">
                                <span>{{ticket.time}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="sold">
                            <th mat-header-cell *matHeaderCellDef><b>Vendidas</b></th>
                            <td mat-cell *matCellDef="let ticket">
                                <span>{{ticket.sold}}</span>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="readed">
                            <th mat-header-cell *matHeaderCellDef><b>Leídas</b></th>
                            <td mat-cell *matCellDef="let ticket">
                                <span>{{ticket.readed}}</span>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </div>
            <ng-template #divNotTickets>
                <p class="text-center m-2" *ngIf="loaded">No existen entradas para este evento</p>
            </ng-template>
        </div>
    </div>
</div>
import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { ApiSalesService } from 'src/app/services/api-sales.service';
import { EventosService } from 'src/app/services/eventos.service';
import { PanelAdminService } from 'src/app/services/panel-admin.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-reports-event',
  templateUrl: './reports-event.component.html',
  styleUrls: ['./reports-event.component.scss']
})
export class ReportsEventComponent implements OnInit {

  idEvent!: any;
  event!: any;

  ticketsSold!: any;
  loaded: boolean = false;
  totalReaded: number = 0;
  displayedColumns: string[] = ['actions', 'date', 'time', 'sold', 'readed'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  business!: any;

  // Fechas
  datesRange!: any;
  stringDate: string = '';
  openDatePicker: boolean = false;
  dateFilter!: any;

  // Metodos
  methodsOptions!: any;
  methodSelected: any[] = [];
  allMethods: boolean = true;

  // Tipo de informe
  typeReport: any = 'global';
  formatReport: any = 'pdf';

  // Header Excel
  reportsGroupEnabled!: any;
  reportsGroupSelected: any[] = [];
  allColumns: boolean = false;

  // Cargar informe
  loadingReport: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private panelAdminService: PanelAdminService, private apiSalesService: ApiSalesService,
    private dialog: MatDialog, private eventService: EventosService, private reportsService: ReportsService) { }

  ngOnInit(): void {
    this.apiSalesService.getAllMethods().subscribe({
      next: (res) => {
        this.methodsOptions = res;
        this.selectAllMethods();
      }
    });

    this.reportsService.getHeaderReports().subscribe({
      next: (res) => {
        this.reportsGroupEnabled = res;
        this.reportsGroupSelected = this.reportsGroupEnabled.map(value => value.id);
      }
    })
  }

  ngAfterViewInit(): void {
    this.business = JSON.parse(localStorage.getItem('business') || '{}');
    this.activatedRoute.params.subscribe({
      next: (params) => {
        this.idEvent = +params.idEvent;
        this.eventService.getEventById(this.idEvent).subscribe({
          next: (res: any) => {
            this.event = res;
            this.eventService.getInfoSalesByEvent(this.idEvent).subscribe({
              next: (res: any) => {
                this.ticketsSold = res.data.tickets;
                this.totalReaded = res.data.totalReaded || 0;
                this.dataSource = new MatTableDataSource(this.ticketsSold);
                this.loaded = true;
              }
            });
          }
        });
      }
    });
  }

  // Seleccionar todas las opciones
  selectAllMethods() {
    this.methodSelected = this.methodsOptions?.map(option => option.id);
  }

  changeTypeReport() {
    if (this.typeReport == 'detail' && this.formatReport == 'pdf') {
      this.formatReport = 'csv';
      this.allColumns = true;
      this.selectAllColumns();
    }
  }

  selectAllColumns() {
    if (this.allColumns) {
      this.reportsGroupSelected = this.reportsGroupEnabled.map(value => value.id);
      this.reportsGroupEnabled.forEach(element => {
        element.selected = true;
      });
    }
  }

  selectColumn(column, i) {
    let index = this.reportsGroupSelected.findIndex(value => value == column.id);
    index == -1 ? this.reportsGroupSelected.push(column.id) : this.reportsGroupSelected.splice(index, 1);
    if (this.reportsGroupEnabled.length != this.reportsGroupSelected.length) {
      this.allColumns = false;
    }
  }

  // Seleccionar Rango de fechas
  selectDates(datesRange: any) {
    this.datesRange = datesRange;
    let stringDates = this.formatStringDate(datesRange.min);
    if (datesRange.max) {
      stringDates += ' - ' + this.formatStringDate(datesRange.max);
    }
    this.stringDate = stringDates;
    this.dateFilter = { date_from: this.formatStringDate(datesRange.min, 'dd/MM/yyyy'), date_to: this.formatStringDate(datesRange.max, 'dd/MM/yyyy') };
  }

  formatStringDate(date: any, format: string = 'dd MMM yy') {
    let dateFormat = null;
    if (date) {
      dateFormat = formatDate(new Date(date), format, 'es');
    }
    return dateFormat;
  }

  clearRangeDates(event: any) {
    if (event) {
      event.stopPropagation();  // Evita que el click se propague al div
    }
    this.datesRange = null;
    this.stringDate = '';
    this.dateFilter = null;
    this.openDatePicker = false;
  }

  downloadPdf() {
    let eventSessions = [];
    let auxDates = this.event.fechas.filter(value => value.activo);
    let auxHours = this.event.horarios.filter((value) => value.activo);
    this.loadingReport = true;

    auxDates.forEach(date => {
      if (!auxHours || auxHours.length > 0) {
        auxHours.forEach(hour => {
          // Controlar si la hora contiene ese dia
          if (!hour.dayMonth || hour.dayMonth.length == 0 || hour.dayMonth.includes(new Date(date.fechaInicio).getDate())) {
            eventSessions.push({ id: this.event.id, multidate: this.event.multidate, name: this.event.titulo, idDate: date.id, date: date.fechaInicio, idHour: hour.id, hour: hour.horaInicio });
          }
        });
      } else {
        eventSessions.push({ id: this.event.id, multidate: this.event.multidate, name: this.event.titulo, idDate: date.id, date: date.fechaInicio, idHour: null, hour: null });
      }
    });
    
    const methods = this.methodSelected?.length != this.methodsOptions?.length ? this.methodSelected : null;
    const reports = this.reportsGroupSelected?.length != this.reportsGroupEnabled?.length ? this.reportsGroupSelected : null;

    const payload = {
      events: eventSessions,
      dates: this.dateFilter,
      format: this.formatReport,
      type: this.typeReport,
      method: methods,
      reportsgroup: reports
    }

    this.reportsService.downloadReportFile(this.business?.id, payload).subscribe({
      next: (res) => {
        if (res) {
          const linkSource = res;
          const type = this.formatReport == 'csv' ? 'text/csv' : 'application/pdf';
          const base64WithMime = `data:${type};base64,${linkSource}`;

          const newTab = window.open('', '_blank');
          if (newTab) {
            newTab.document.body.innerHTML = `
              <iframe src="${base64WithMime}" frameborder="0" style="border:0; width:100%; height:100%;" allowfullscreen></iframe>
            `;
            newTab.document.title = 'Informe';
          }
        }
        this.loadingReport = false;
      }, error: (err) => {
        this.infoDialog('¡Vaya! Algo ha fallado, no se ha podido descargar el informe. Vuelve a intentarlo más tarde.');
        this.loadingReport = false;
      }
    })
  }

  infoDialog(msg: string, icon: boolean = true) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OrdersEventService } from 'src/app/services/orders-event.service';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-detail-order',
  templateUrl: './detail-order.component.html',
  styleUrls: ['./detail-order.component.scss']
})
export class DetailOrderComponent implements OnInit {

  idOrder!: any;
  order!: any;
  seats: any[] = [];

  fees: number = 0;
  billingOriginal: any;

  allowCitylok: boolean = false;
  allowRefund: boolean = false;
  now: Date = new Date();

  constructor(private activatedRoute: ActivatedRoute, private orderService: OrdersEventService, private reportService: ReportsService,
    private dialog: MatDialog, private auth: AuthenticationService) { }

  ngOnInit(): void {
    // Permisos temporales para realizar devoluciones
    let rolesProfile = this.auth.getProfile()?.roleFunctions.map((role: any) => role.role.code);
    rolesProfile?.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'CREATE_EVENTS' || element == 'ACCESS_SALES' || element == 'ACCESS_SUPPORT') {
        this.allowRefund = true;
      }
    });
    this.allowCitylok = this.auth.getProfile()?.citylok;
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      let idOrder: number = params.search;
      if (idOrder) {
        this.getOrder(idOrder);
      }
    })
  }

  getOrder(idOrder: any) {
    this.seats = [];
    this.fees = 0;
    this.orderService.getOrdersById(idOrder).subscribe({
      next: (res) => {
        this.order = res.transaction;
        let seatsJson = Object.values(JSON.parse(this.order.seats));
        seatsJson?.forEach((element: any) => {
          element['refund'] = false;
          let obj = this.seats.find(value => value.id_event == element.id_event && value.date_event == element.date_event && value.hour_event == element.hour_event); // Comprobar también la fecha y la hora del evento
          if (!obj) {
            this.seats.push({ id_event: element.id_event, name_event: element.name_event, date_event: element.date_event, hour_event: element.hour_event, url_image: element.url_image, location: element.location, items: [element] });
          } else {
            obj.items.push(element);
          }
          this.fees += element.fee * 1.21;
        });
        this.order.transaction_refunds?.forEach(refund => {
          let seatsRefund = JSON.parse(refund.seats);
          seatsRefund.forEach(element => {
            element['refund'] = true;
            let obj = this.seats.find(value => value.id_event == element.id_event && value.date_event == element.date_event && value.hour_event == element.hour_event); // Comprobar también la fecha y la hora del evento
            if (!obj) {
              this.seats.push({ id_event: element.id_event, name_event: element.name_event, date_event: element.date_event, hour_event: element.hour_event, url_image: element.url_image, location: element.location, items: [element] });
            } else {
              obj.items.push(element);
            }
            this.fees += element.fee * 1.21;
          });
        });
        this.billingOriginal = res.billing_info;
      }
    });
  }

  printSingleA4Ticket(ticket: any) {
    this.getUrlDownloadSigleTickets(ticket.id, 'a4');
  }

  printSigleTicket(ticket: any) {
    this.getUrlDownloadSigleTickets(ticket.id, 'ticket');
  }

  getUrlDownloadSigleTickets(ticket: any, type: any) {
    this.reportService.getUrlsDownload(this.order.id).subscribe({
      next: (url) => {
        let param = type == 'a4' ? 'ticket_a4' : 'ticket';
        let uriDownload = `${url[param]}?booking_ids[]=${ticket}`;
        window.open(uriDownload, '_blank');
        this.openInfoCard('Se ha descargado el ticket correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    })
  }

  refundOrder() {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que quieres devolver el pedido? Esta acción no podrá deshacerse.',
        btnCancel: true,
        warning: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reportService.cancelOrder(this.order.id, {}).subscribe({
          next: (url) => {
            this.openInfoCard('Se ha devuelto el pedido correctamente.', false, false, true);
          }, error: (err) => {
            this.openInfoCard('Lo sentimos, no se ha podido devolver el pedido, vuelve a intentarlo más tarde.', true, false);
          }
        })
      }
    })
  }

  refundTicket(ticket: any) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      disableClose: true,
      data: {
        icon: true,
        text: '¿Estás seguro de que quieres devolver la entrada seleccionada? Esta acción no podrá deshacerse.',
        btnCancel: true,
        warning: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reportService.cancelSeat(this.order.id, {bookings: [ticket.id]}).subscribe({
          next: (url) => {
            this.openInfoCard('La entrada se ha devuelto correctamente.', false, false, true);
          }, error: (err) => {
            this.openInfoCard('Lo sentimos, no se ha podido cancelar la entrada, vuelve a intentarlo más tarde.', true, false);
          }
        })
      }
    })
  }

  openConfirm(msg?: string, close?: boolean): void {
    let data = {
      icon: true,
      text: msg,
      btnCancel: true
    };
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: 'info-dialog',
      data: data
    });
    // dialogRef.afterClosed().subscribe((result) => {
    //   if (result && close) {
    //     this.location.back();
    //   }
    // });
  }

  openPrintA4() {
    this.downloadTicketOrder(this.order.id, 'a4');
  }

  openPrintTicket() {
    this.downloadTicketOrder(this.order.id, 'ticket');
  }

  downloadTicketOrder(idOrder: number, type: string) {
    this.reportService.getUrlsDownload(idOrder).subscribe({
      next: (url) => {
        let param = type == 'a4' ? 'ticket_a4' : 'ticket';
        let uriDownload = url[param];
        window.open(uriDownload, '_blank');
        this.openInfoCard('Se ha descargado el ticket correctamente.', false, false);
      }, error: (err) => {
        this.openInfoCard('Lo sentimos, no se han podido descargar las entradas.', true, false);
      }
    })
  }

  openInfoCard(msg: string, disabledClose: boolean = false, icon: boolean = true, refund: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        icon: icon,
        text: msg,
        btnCancel: false
      }
    });
    if(refund) {
      dialogRef.afterClosed().subscribe({next: (res) => {
        this.getOrder(this.order.id);
      }})
    }
  }

}

<div class="edit-ticket form-new-lok">
    <div class="d-flex btns-close-section align-items-center justify-content-end pointer-in">
        <div class="icon-close" mat-dialog-close>
            <i class="fa-regular fa-times"></i>
        </div>
    </div>
    <!-- Form new ticket -->
    <form [formGroup]="entrada" class="form-entrada">
        <mat-form-field appearence="fill" class="label-title mb-2" [hideRequiredMarker]="true">
            <mat-label class="d-flex justify-content-between w-100">
                <span>Nombre entrada*</span>
                <span class="count-letters">{{ 50 - nameTicket.value.length }}</span>
            </mat-label>
            <input #nameTicket matInput type="text" placeholder="Nombre entrada" maxlength="50" formControlName="nombre"
                required>
        </mat-form-field>

        <mat-form-field appearence="fill" class="label-title mb-2" [hideRequiredMarker]="true">
            <mat-label class="d-flex justify-content-between w-100">
                <span>Descripción</span>
                <span class="count-letters">{{ 250 - descriptionTicket.value.length }}</span>
            </mat-label>
            <input #descriptionTicket matInput type="text" placeholder="Descripción entrada" maxlength="250"
                formControlName="description">
        </mat-form-field>

        <div class="d-flex align-items-center justify-content-between container-price-ticket">
            <div class="d-flex align-items-center justify-content-between">
                <p class="m-0">Precio:</p>
            </div>
            <div class="input-border input-ticket" *ngIf="levels?.length <= 0">
                <input matInput class="pointer-in" type="number" [value]="singlePrice"
                    (change)="addPriceTicket($event, null, null)">
                <i class="fa-light fa-euro-sign icon-primary"></i>
            </div>
        </div>
        <div class="d-flex align-items-center justify-content-between container-price-ticket">
            <mat-checkbox [value]="onlyTpv" (change)="editOnlyTpv($event)" color="primary">Solo venta en
                taquilla</mat-checkbox>
        </div>
        <!-- Entradas para una zona -->
        <div *ngIf="levels?.length > 0">
            <div>
                <!-- Info horarios -->
                <div class="container-info-hours" style="margin-bottom: 10px; margin-top: 10px;">
                    <i class="fa-light fa-circle-info icon-check"></i>
                    <p class="m-0">Introduce el precio que tendrá la entrada en las zonas donde esté disponible.</p>
                </div>
                <!-- <mat-checkbox class="title-input" [(ngModel)]="ticketZone"
                        [ngModelOptions]="{standalone: true}">
                        <span class="title-input">Entrada para una zona concreta</span></mat-checkbox> -->
                <div>
                    <div *ngFor="let level of levels; let i = index">
                        <div class="d-flex justify-content-between align-items-center section-price price-level">
                            <div class="section-item">
                                <div class="color-section" [style.background]="level.color"></div>
                                <p class="m-0">{{level.name}}</p>
                            </div>
                            <div>
                                <div class="input-border input-ticket">
                                    <input matInput class="pointer-in" type="number" [value]="level.price"
                                        (change)="addPriceTicket($event, level, i)">
                                    <i class="fa-light fa-euro-sign icon-primary"></i>
                                </div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider *ngIf="levels?.length <= 0"></mat-divider>

        <div
            *ngIf="false&& ((datesOptions?.length > 0 && hoursOptions?.length > 0 && allowTicketSpecial) || allowSubscription)">
            <div class="d-flex align-items-center justify-content-between edit-advanced"
                (click)="openSpecialTickets=!openSpecialTickets">
                <p class="m-0">Entradas especiales</p>
                <i class="fa-light icon-primary" [class]="openSpecialTickets?'fa-chevron-up':'fa-chevron-down'"></i>
            </div>
            <div *ngIf="openSpecialTickets">
                <!-- Entradas especiales -->
                <div class="padding-inputs" style="padding-top: 0px;">
                    <!-- Entradas para socios -->
                    <div class="special-tickets" *ngIf="allowSubscription">
                        <mat-checkbox class="title-input" formControlName="onlySubscribers">
                            <span class="title-input">Entrada para socios*</span></mat-checkbox>
                        <p class="info-special-ticket">*La entrada estará disponible únicamente para usuarios que sean
                            miembros del espacio.</p>
                    </div>
                    <mat-divider *ngIf="allowSubscription"></mat-divider>

                    <div *ngIf="datesOptions?.length > 0 && hoursOptions?.length > 0 && allowTicketSpecial">
                        <!-- Entradas para días concretos -->
                        <div class="special-tickets">
                            <mat-checkbox class="title-input" formControlName="specialTime">
                                <span class="title-input">Entrada para días concretos</span></mat-checkbox>
                            <!-- <p class="m-0">Entradas para días concretos</p> -->
                            <p class="info-special-ticket">(Por ejemplo: Entrada disponible únicamente para el viernes
                                o para el sábado)</p>
                            <div class="section-chips" *ngIf="entrada?.value.specialTime">
                                <!-- Se mostrarán en chips los días con las que cuenta el evento -->
                                <div *ngIf="datesOptions?.length > 1">
                                    <p class="m-0">Selecciona las fechas:</p>
                                    <div class="chips-dates d-flex">
                                        <div *ngFor="let date of datesOptions; let i = index" class="d-flex"
                                            (click)="selectDateSpecial(date)">
                                            <div class="chips-times row align-items-center">
                                                <span class="chip-time pointer-in col" [class.active]="date.selected">
                                                    {{ date | date:'EEE dd MMM' }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="hoursOptions?.length > 1">
                                    <p class="m-0">Selecciona los horarios:</p>
                                    <!-- Se mostrarán en chips los días con las que cuenta el evento -->
                                    <div class="chips-dates d-flex">
                                        <!-- Seleccionar horarios -->
                                        <div *ngFor="let time of hoursOptions; let i = index" class="d-flex"
                                            (click)="selectTimeSpecial(time)">
                                            <div class="chips-times row align-items-center">
                                                <span class="chip-time pointer-in col" [class.active]="time.selected">
                                                    {{ time.horaInicio | date:'HH:mm':'+0000' }}h
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <!-- Entradas tipo festival -->
                        <div class="special-tickets" *ngIf="entrada?.value.specialTime && datesOptions?.length > 1">
                            <mat-checkbox class="title-input" formControlName="typeBond">
                                <span class="title-input">Entrada válida para varios días</span></mat-checkbox>
                            <!-- <p class="m-0">Entradas para varios días</p> -->
                            <p class="info-special-ticket">Por defecto, la entrada será valida únicamente para un día,
                                marca esta casilla si quieres que esta entrada sea "tipo festival" y sea válida para
                                todos los días seleccionados al comprarla.
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Info horarios -->
                <div class="container-info-hours" style="margin-bottom: 10px;" *ngIf="allowTicketSpecial">
                    <i class="fa-light fa-circle-info icon-check"></i>
                    <p class="m-0">¿Necesitas más opciones?
                        Añádelos desde el apartado Fecha y Hora en la información del evento</p>
                </div>
            </div>
            <mat-divider></mat-divider>
        </div>

        <div class="d-flex align-items-center justify-content-between edit-advanced"
            (click)="openAdvanced=!openAdvanced" *ngIf="false">
            <p class="m-0">Ajustes avanzados</p>
            <i class="fa-light icon-primary" [class]="openAdvanced?'fa-chevron-up':'fa-chevron-down'"></i>
        </div>
        <div class="padding-inputs" *ngIf="openAdvanced">
            <!-- Venta de entradas -->
            <div *ngIf="false">
                <p>Inicio de venta de entradas</p>
                <p style="font-size: 14px;">Será prioritaria la fecha de venta general del evento.</p>
                <div class="section-input">
                    <!-- Fecha inicio venta -->
                    <div class="d-flex align-items-center justify-content-between input-border">
                        <div class="select-style calendar-date pointer-in" [class.active]="typeDatepicker == 'start'"
                            (click)="openDatePicker('start')">
                            <i class="fa-light fa-calendar"></i>
                            <input placeholder="Inicio de venta" class="input-date" readonly
                                [(ngModel)]="startStringDate" [ngModelOptions]="{standalone: true}">
                            <i class="icon-down fa-light fa-chevron-down"></i>
                        </div>
                        <div class="calendar-option" *ngIf="typeDatepicker == 'start'">
                            <app-default-calendar [selectedDate]="startDate"
                                (saveDate)="saveDate($event)"></app-default-calendar>
                        </div>
                    </div>
                    <!-- Hora inicio venta -->
                    <div class="d-flex align-items-center justify-content-center inputs-hours">
                        <div class="input-hour">
                            <input placeholder="20" type="number" min="0" max="23" required [(ngModel)]="hourInit"
                                [ngModelOptions]="{standalone: true}" />
                        </div>
                        <span class="points">:</span>
                        <div class="input-hour">
                            <input style="text-align: right;" placeholder="00" type="number" min="0" max="59" required
                                [(ngModel)]="minuteInit" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                </div>

                <div class="section-input">
                    <!-- Fecha fin venta -->
                    <div class="d-flex align-items-center justify-content-between input-border">
                        <div class="select-style calendar-date pointer-in" [class.active]="typeDatepicker == 'end'"
                            (click)="openDatePicker('end')">
                            <i class="fa-light fa-calendar"></i>
                            <input placeholder="Fin de venta" class="input-date" readonly [(ngModel)]="endStringDate"
                                [ngModelOptions]="{standalone: true}">
                            <i class="icon-down fa-light fa-chevron-down"></i>
                        </div>
                        <div class="calendar-option" *ngIf="typeDatepicker == 'end'">
                            <app-default-calendar [selectedDate]="endDate"
                                (saveDate)="saveDate($event)"></app-default-calendar>
                        </div>
                    </div>
                    <!-- Hora fin venta -->
                    <div class="d-flex align-items-center justify-content-center inputs-hours">
                        <div class="input-hour">
                            <input placeholder="20" type="number" min="0" max="23" required [(ngModel)]="hourEnd"
                                [ngModelOptions]="{standalone: true}" />
                        </div>
                        <span class="points">:</span>
                        <div class="input-hour">
                            <input style="text-align: right;" placeholder="00" type="number" min="0" max="59" required
                                [(ngModel)]="minuteEnd" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                </div>
            </div>
            <p>Entradas por pedido</p>
            <div class="section-input section-max-sales">
                <!-- Entradas minimas -->
                <div class="input-border input-min">
                    <span>Mínimo:</span>
                    <input class="pointer-in" placeholder="1" type="number" min="1" formControlName="ticketsMin">
                </div>
                <!-- Entradas maximas -->
                <div class="input-border input-max">
                    <span>Máximo:</span>
                    <input class="pointer-in" placeholder="10" type="number" min="1" formControlName="ticketsMax">
                </div>
            </div>
        </div>

        <!-- <div class="container-info-hours mb-1" *ngIf="entrada.value.descuento">
            <i class="fa-light fa-circle-info icon-check"></i>
            <p class="m-0">El descuento se aplicará al precio establecido.</p>
        </div> -->
        <!-- Horarios especiales -->
        <!-- <mat-divider></mat-divider> -->
        <!-- <div class="section-free">
            <div class="d-flex align-items-center justify-content-between">
                <p class="title-free">Horarios especiales</p>
                <mat-slide-toggle formControlName="specialTime"
                    (change)="changeSpecialTimes($event)"></mat-slide-toggle>
            </div> 
        -->

        <!-- Seleccionar horarios -->
        <!-- <div class="special-times" *ngIf="entrada.controls['specialTime'].value">
                <div *ngFor="let schedule of optionsTime; let i = index" class="d-flex">
                    <span class="col text-schedule" [class.small]="!typeDates">
                        {{ !typeDates ? (schedule.stringDate) : (schedule.day | date:'EEE dd' | uppercase) }}
                    </span>
                    <div class="chips-times row align-items-center" *ngIf="schedule.times">
                        <div *ngFor="let time of schedule.times; let n = index"
                            (click)="addSpecialTime(time.horaInicio, schedule)" class="chip-time pointer-in col"
                            [class.active]="specialTimes[(time.horaInicio | date:'HH:mm':'+0000')] && specialTimes[(time.horaInicio | date:'HH:mm':'+0000')].includes(schedule.day)">
                            <span>{{ time.horaInicio | date:'HH:mm':"+0000" }}h</span>
                        </div>
                    </div>
                    <div class="chips-times row align-items-center" *ngIf="!schedule.times">
                        <div (click)="addSpecialTime(schedule.horaInicio, schedule)" class="chip-time pointer-in col"
                            [class.active]="specialTimes[(schedule.horaInicio | date:'HH:mm':'+0000')]?.length > 0">
                            <span>{{ schedule.horaInicio | date:'HH:mm':'+0000' }}h</span>
                        </div>
                    </div>
                </div>
            </div> -->
        <!-- </div> -->

    </form>

    <!-- Añadir ticket -->
    <button class="btn-new-form btn-save w-100" (click)="saveTicket()">GUARDAR</button>
</div>
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { InfoCardComponent } from 'src/app/components/shared/info-card/info-card.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PermissionService } from 'src/app/services/permission.service';
import { SubscriberService } from 'src/app/services/subscriber.service';
import { NewComunicationComponent } from '../new-comunication/new-comunication.component';
import { NewSubscriberComponent } from '../new-subscriber/new-subscriber.component';

@Component({
  selector: 'app-subscribers-list',
  templateUrl: './subscribers-list.component.html',
  styleUrls: ['./subscribers-list.component.scss']
})
export class SubscribersListComponent implements OnInit {

  searchText!: string;
  loadingTable: boolean = true;
  finishScroll: boolean = false;
  loadingScroll: boolean = false;
  loadingSort: boolean = false;
  loaded: boolean = false;
  subscribers!: any[];
  now: Date = new Date();

  displayedColumns: string[] = ['options', 'name', 'email', 'createdAt', 'status'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  @ViewChild(MatSort) sort: MatSort = new MatSort;
  sorted!: any;

  page = 1;
  totalPages = 1;

  @ViewChild('containerScroll', { static: false }) containerScroll: ElementRef;
  activeSelectList: boolean = false;
  allSelected: boolean = false;
  selectedItems: any[] = [];

  private searchTerms = new Subject<string>();
  business = JSON.parse(localStorage.getItem('business') || '{}');
  places = JSON.parse(localStorage.getItem('places') || '[]');
  profile = this.auth.getProfile();
  rolesProfile: any[] = this.profile?.roleFunctions.map((role: any) => role.role.code);
  marketing: boolean = false;

  // Numero de socios
  numMembers: number = 0;

  constructor(private permissionService: PermissionService, private router: Router, private subscriberService: SubscriberService, private dialog: MatDialog,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.rolesProfile.forEach(element => {
      if (element == 'ACCESS_CLOUD' || element == 'ACCOUNT_OWNER' || element == 'ACCESS_EXTERN_EVENTS' || element == 'ACCESS_ADMIN' || element == 'ACCESS_MANAGER' || element == 'ACCESS_MARKETING') {
        this.marketing = true;
        return;
      }
    });
  }

  ngAfterViewInit() {
    this.subscribers = [];
    this.getData();
    this.searchTerms.pipe(
      debounceTime(300), // Espera 300ms después de cada pulsación de tecla
      distinctUntilChanged(), // Ignora si el nuevo término de búsqueda es igual al anterior
    ).subscribe(items => {
      this.getFilter();
    });
  }

  getData() {
    this.subscriberService.getCountSubscribers(this.business?.id).subscribe({
      next: (data) => {
        this.numMembers = data.count;
      }
    })
    this.subscriberService.getSubscribers(this.business?.id, this.page, this.searchText, this.sorted).subscribe({
      next: (res) => {
        if (res) {
          this.subscribers = this.subscribers.concat(res);
          if (this.subscribers.length <= 0 || this.subscribers.length < 20) {
            this.finishScroll = true;
          }
          this.dataSource = new MatTableDataSource(this.subscribers);
          if (this.allSelected) {
            this.selectAll();
          }
        }
        this.loaded = true;
        this.loadingTable = false;
        this.loadingScroll = false;
        this.loadingSort = false;
      },
      error: (err) => {
        this.loaded = true;
        this.loadingTable = false;
        this.loadingScroll = false;
        this.loadingSort = false;
      }
    });
  }

  getFilter() {
    this.subscribers = [];
    this.page = 1;
    this.loadingTable = true;
    this.loadingScroll = true;
    this.finishScroll = false;
    this.getData();
  }

  searchUser(res: string) {
    this.searchText = res;
    this.searchTerms.next(this.searchText);
  }

  openUser(id: number) {
    this.router.navigate(['/panel-control/config/permission/user', id]);
  }

  // Sort
  sortData(sort: Sort) {
    this.loadingSort = true;
    const isAsc = (sort.direction === 'asc') ? 'asc' : 'desc';
    if (sort.direction) {
      let sortedBy = sort.active == 'name' ? 'nombre' : sort.active;
      this.sorted = { sortedBy: sortedBy, sort: isAsc };
    } else {
      this.sorted = null;
    }
    this.getFilter();
  }

  addSubscriber() {
    if (!this.activeSelectList) {
      this.dialog.open(NewSubscriberComponent, {
        panelClass: ['info-dialog', 'not-padding'],
        disableClose: true,
        minWidth: '30vw',
        data: { idCompany: 1 }
      }).afterClosed().subscribe((res) => {
        if (res?.user) {
          this.searchText = '';
          this.getFilter();
          this.openInfoCard('Usuario añadido correctamente.');
        }
        if (res?.err) {
          this.openInfoCard(res.err);
        }
      });
    }
  }

  openCreateComunication() {
    if (!this.activeSelectList) {
      this.dialog.open(NewComunicationComponent, {
        panelClass: ['info-dialog', 'not-padding'],
        disableClose: true,
        minWidth: '40vw',
        data: { idCompany: this.business?.id }
      }).afterClosed().subscribe((res) => {
        if (res.result) {
          this.openInfoCard('Comunicación enviada correctamente a todos los miembros.');
        } else if(res.err) {
          this.openInfoCard(res.err);
        }
      });
    }
  }

  getStatus(statusKey: string) {
    let status = '';
    switch (statusKey) {
      case 'pending':
        status = 'Pendiente';
        break;
      case 'active':
        status = 'Activo';
        break;
      case 'inactive':
        status = 'Desactivado';
        break;
      case 'blocked':
        status = 'Invitación enviada';
        break;
      default:
        break;
    }
    return status;
  }

  getStatusColor(statusKey: string) {
    let color = '';
    switch (statusKey) {
      case 'pending':
        color = '#E9A41B';
        break;
      case 'active':
        color = '#21CB7C';
        break;
      case 'inactive':
        color = '#7A7A7A';
        break;
      case 'blocked':
        color = '#FF4D80';
        break;
      default:
        break;
    }
    return color;
  }

  activeSelecting() {
    this.activeSelectList = !this.activeSelectList;
    // Eliminar la seleccion actual
  }

  selectAll() {
    this.dataSource.data.forEach((item) => item.selected = this.allSelected);
    this.selectedItems = this.dataSource.data.filter((item) => item.selected);
  }

  changeSelectItem(item: any) {
    item.selected = !item.selected;
    this.selectedItems = this.dataSource.data.filter((item) => item.selected);
    this.allSelected = this.selectedItems.length === this.dataSource.data.length;
  }

  containsBlocked() {
    return this.selectedItems.filter((item) => item.status == 'blocked').length <= 0;
  }

  openInfoCard(msg: string, disabledClose: boolean = false) {
    const dialogRef = this.dialog.open(InfoCardComponent, {
      panelClass: ['info-dialog'],
      disableClose: disabledClose,
      data: {
        text: msg
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getFilter();
    });
  }

  editSubscription(id: number, status: string) {
    this.subscriberService.editSubscription(id, { status: status }).subscribe({
      next: (res) => {
        this.openInfoCard('Usuario actualizado correctamente.');
      },
      error: (err) => {
        this.openInfoCard('Error al realizar la acción.');
      }
    });
  }

  editSelected(status: string) {
    let list = this.selectedItems.map((item) => item.id);
    this.subscriberService.editListSubscriptions({subscribers: list, status: status }).subscribe({
      next: (res) => {
        this.openInfoCard('Usuarios actualizados correctamente.');
      },
      error: (err) => {
        this.openInfoCard('Error al realizar la acción.');
      }
    });
  }

  deleteSelected() {
    if (this.selectedItems.length > 0) {
      this.dialog.open(InfoCardComponent, {
        panelClass: ['info-dialog'],
        disableClose: true,
        data: {
          icon: true,
          text: '¿Estás seguro de que deseas eliminar los miembros seleccionados?',
        }
      }).afterClosed().subscribe((res) => {
        if (res) {
          this.selectedItems.forEach((item) => {
            // this.subscriberService.deleteSubscriber(item.id).subscribe({
            //   next: (res) => {
            //     this.openInfoCard('Usuario eliminado correctamente.');
            //   },
            //   error: (err) => {
            //     this.openInfoCard('Error al realizar la acción.');
            //   }
            // });
          });
        }
      });
    }
  }

  //Pagination
  @HostListener('window:scroll', ['$event'])
  @HostListener('scroll')
  public isScrolledInfinityView(event) {
    if (!this.loadingTable) {
      if (!this.finishScroll) {
        const element = this.containerScroll.nativeElement.getBoundingClientRect();
        let bottomShown = element.bottom <= window.innerHeight;
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
          bottomShown = true;
        }
        if (bottomShown && !this.finishScroll) {
          this.page++;
          this.loadingScroll = true;
          setTimeout(() => {
            this.getData();
          }, 200);
        }
      } else {
        this.finishScroll = true;
      }
    }
  }

}
